import { axiosInstance } from "../utils/request"

let results: Array<component>;
const HEALTHY: string = "Healthy";
const UNHEALTHY: string = "Unhealthy";

// get the Results from Healthcheck endpoints
export const getHealthCheckData = async () => {
    results = new Array<component>();

    let healthCheckUrls = process.env.REACT_APP_HEALTH_CHECK_URLS?.split(';') || [];
    for (let url in healthCheckUrls) {
        if(healthCheckUrls[url].trim() == '') continue;
        let keys = healthCheckUrls[url].split('|');
        //fetch the API after combining the API base path and config values
        await fetchHealthStatus(`${keys[1]}`, keys[0], keys[1].indexOf('/healthcheck') > -1 );
    }
    return results;
};

// Fetch and build the results object
const fetchHealthStatus = (url: string, name: string, format: boolean = true) => {
    return new Promise(resolve => {
        let promise: any = axiosInstance.get(url, {}).then((res) => {
            if(format) {
                results.push(buildResults(res, name, ''));
            } else {
                results.push({ name: name, status: 1.0, datapoints: [], elements: [{name:'URL', status: 1.0, datapoints: []}] });
            }
        }).catch((err) => {
            results.push(buildResults(err.response ? err.response.data : { status: UNHEALTHY}, name, err.message));
        })
        resolve(promise);
    });
};

function buildResults(result: any, url: string, detail: string): any {
    let item: component = { name: url, status: getStatus(result.status), datapoints: [], elements: [] };
    let entries = result.entries;
    for (const x in entries) {
        item.elements.push({name: x, status:getStatus(entries[x].status), datapoints: [], description: entries[x].description});
    }
    // If no entries found, assume API failed
    if (!entries) {
        item.elements.push({name: `API error: ${detail}`, status: 0, datapoints: []});
    }
    return item;
}

// Map the status to the number value
function getStatus(status: string): number {
    let numStatus: number = 0.5;
    switch (status) {
        case HEALTHY:
            numStatus = 1.0;
            break;
        case UNHEALTHY:
            numStatus = 0.0;
            break;
        default:
            break;
    }
    return numStatus;
}
