import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_PATH || "https://localhost",
    timeout: 5000,
    withCredentials: false,
    headers: {
        'Content-type': 'application/json'
    }
});
axiosInstance.interceptors.response.use(
    (res) => {
        return res.data;
    },
    async (err) => {
        return Promise.reject(err);
    }
);

export default axiosInstance;