import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from '../components/home';

function AppRoutes() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
  );
}

export default AppRoutes;
