import { useState, useEffect } from 'react';
import HealthDashboard from 'react-health-dashboard'
import 'react-health-dashboard/dist/index.css'
import { getHealthCheckData } from '../../services/healthcheck'

const initialData: any = [
  {
    name: 'API', // name of the main component
    status: 1.0, // health of the main component (this is the leftmost bubble before the component name). Max = 1 (Green) and Min = 0 (Red). Any value between 1 and 0 will be Yellow. Any value outside of the range 0-1, will be Grey.
    lastCheckTime: 1611990000000, // Optional. The timestamp of the latest health check
    datapoints: [ // These datapoints represent the health of the main component over time.
      { timestamp: '1611964800000', value: 1.0 }, // timestamp is the timestamp of the healthcheck. value is the health of the main component. Max = 1 (Green) and Min = 0 (Red). Any value between 1 and 0 will be Yellow. Any value outside of the range 0-1, will be Grey.
      { timestamp: '1611968400000', value: 1.0 },
    ],
    elements: [ // These are the sub components and their health
      {
        name: 'Cloud', // name of the sub component
        status: 1.0, // health of the sub component (this is the leftmost bubble before the sub component name). Max = 1 (Green) and Min = 0 (Red). Any value between 1 and 0 will be Yellow. Any value outside of the range 0-1, will be Grey.
        lastCheckTime: 1611990000000, // Optional. The timestamp of the latest health check
        datapoints: [
          { timestamp: '1611964800000', value: 1.0 }, // timestamp is the timestamp of the healthcheck. value is the health of the sub-component. Max = 1 (Green) and Min = 0 (Red). Any value between 1 and 0 will be Yellow. Any value outside of the range 0-1, will be Grey.
          { timestamp: '1611968400000', value: 1.0 }
        ]
      }
    ]
  }];


export default function Home() {

  const [healthData, setHealthCheckData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const updateHealthCheckResults = () => {
    setLoading(true);

    getHealthCheckData().then((result) => {
      setHealthCheckData(result);
      setLoading(false);
    });
  }
  // initialise APIs after component mounted
  useEffect(() => {
    updateHealthCheckResults();
  }, [])

  return (
    <>
      <button type='button' onClick={() => updateHealthCheckResults()}>
        Refresh
      </button>
      <HealthDashboard loading={loading}
        data={healthData}
      />
    </>);
};
